/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

//for keep actions dropdown visibe in workspaces list 

// Variables

$theme_color1 : #433cd8;
$theme_color2 : #4eb5ef;
$theme_color3 : #433cd8;

@mixin flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

html
body{
	background-color: #f3f2ef;
}

.theme_color1{
	color: $theme_color1;
}

.theme_color2{
	color: $theme_color2;
}

.workspace-list .rdt_TableBody {
	min-height: 250px;
}

.companies-list{
	.TUEFO {
		background-color: transparent;
	}
	.rdt_TableBody{
		min-height: 250px;
	}
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):focus{
	background-color: transparent !important;
}

.nav-pills .nav-link,
.btn {
	border-radius: 50px;
	box-shadow: none !important;
}

.header-navbar.floating-nav,
.card{
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / .05%);
	border-radius: 10px;
}

.main-menu.menu-shadow{
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / .05%);
}

.btn-primay:hover,
a:not([href]):hover{
	color: #4da6f9;
}

.dropdown-menu .dropdown-item {
	width: 100%;
}

.main-menu .navbar-header .navbar-brand .brand-text{
	color:#302f4b;
}

.vertical-layout .main-menu .navigation a.active{
	box-shadow: none;
	border-radius: 50px;
}

span.divider{
	border: 1px solid #eeeeee;
}

.bs-popover-auto{
	border-radius: 15px !important;
	margin-top: 10px;
	margin-left: 10px;
}

.popover .popover-arrow{
	top: -10px !important;
}

.bs-popover-auto .popover-header{
	border-radius: 15px 15px 0 0;
}

.main-menu .workspace-outer{
	position: relative;
	padding: 10px 15px 15px;
}

.main-menu .navbar-header .navbar-brand{
	margin-top: 1rem;
}

.vertical-layout .modern-nav-toggle {
	@include flex-center;
	display: flex !important;
	position: relative;
	top:-5px;
	height: 22px;
	width: 22px;
	border: 1px solid;
	color: #ffffff;
	background-color: $theme_color1;
	border-color: $theme_color1;
	border-radius: 50%;
	font-size: 12px;
}

.vertical-layout .modern-nav-toggle svg{
	margin: 0 !important;
}

.vertical-layout.menu-collapsed .modern-nav-toggle {
	background-color: #ffffff;
	color: $theme_color1;
}

.vertical-layout.menu-collapsed #controlledPopover{
	@include flex-center;
}

.vertical-layout.menu-collapsed #controlledPopover .avatar {
	margin-left: 14px;
}

.vertical-layout .expanded #controlledPopover .avatar {
	margin-left: 0;
}

.top-alert {
	padding: 1rem 2rem 0;
    margin-left: 260px;
}

.cursor-pointer {
	cursor: pointer;
}

.phone-placeholder input {
	border: none !important;
	cursor: auto !important;
}

.phone-placeholder .flag .arrow {
	display: none !important;
}

.phone-placeholder .flag-dropdown {
	border: none !important;
	background: none !important;
}

.card {
	box-shadow: rgb(51 48 60 / 3%) 0px 3px 9px 1px, rgb(51 48 60 / 2%) 0px 8px 9px 0px, rgb(51 48 60 / 1%) 0px 1px 6px 4px;
}

.bx-shadow {
	box-shadow: rgb(51 48 60 / 3%) 0px 3px 9px 1px, rgb(51 48 60 / 2%) 0px 8px 9px 0px, rgb(51 48 60 / 1%) 0px 1px 6px 4px;
}

#controlledPopover {
	padding: 3px !important;
	// padding-left: 10px !important ;
}

.header-navbar {
	&.floating-nav {
	z-index: 5 !important;
	}
}

@media (min-width: 1200px) {
	.app-content {
		&.content {
			padding-top: 2rem !important;
			z-index: 13 !important;
		}
	}
}

