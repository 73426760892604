a.badge:hover {
  color: $white;
}
.bg-success{
  color: #fff!important;
  background-color: #00FF40!important;
}
.bg-danger{
  color: #fff!important;
  background-color: rgb(255, 6, 6) !important;
}
